import React from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildLatestVideosForGenerationPath, MediaModel } from 'client/data/models/media';
import { AnchorNavContent } from 'site-modules/shared/components/anchor-nav-sticky/anchor-nav-content';
import { compact } from 'lodash';
import { MENU_HASH, REVIEW_MENU_HASH } from 'site-modules/core-page/utils/constants';

function ReviewAnchorNavUI({ hasShortVideos, hasRangeAndCost, hasEvInsights, hasVideos, isMobile }) {
  const navList = compact([
    { hash: REVIEW_MENU_HASH.HIGHLIGHTS, name: 'Pros & Cons', customOffset: -150 },
    hasShortVideos && { hash: REVIEW_MENU_HASH.SHORTS, name: 'Video Shorts' },
    { hash: REVIEW_MENU_HASH.OVERVIEW, name: 'Overview' },
    hasRangeAndCost && { hash: REVIEW_MENU_HASH.COST_TO_DRIVE, name: 'Cost to Drive' },
    hasEvInsights && { hash: REVIEW_MENU_HASH.EV_INSIGHTS, name: 'EV Insights' },
    { hash: REVIEW_MENU_HASH.RATING, name: 'Edmunds Expert Rating' },
    hasVideos && { hash: MENU_HASH.VIDEOS, name: 'Video Review' },
  ]);

  return (
    <AnchorNavContent
      navList={navList}
      isMobile={isMobile}
      className="w-100 mb-1_5"
      listClassName="d-flex align-items-end"
      linkClassName="medium pt-1 pb-0_75"
      activeClass="link-active text-blue-40 fw-medium"
      linkTrackingId="view_review_content"
    />
  );
}

ReviewAnchorNavUI.propTypes = {
  hasShortVideos: PropTypes.bool,
  hasRangeAndCost: PropTypes.bool,
  hasEvInsights: PropTypes.bool,
  hasVideos: PropTypes.bool,
  isMobile: PropTypes.bool,
};

ReviewAnchorNavUI.defaultProps = {
  hasShortVideos: false,
  hasRangeAndCost: false,
  hasEvInsights: false,
  hasVideos: false,
  isMobile: false,
};

export const ReviewAnchorNav = connectToModel(ReviewAnchorNavUI, {
  // will be different path for used
  hasVideos: bindToPath(
    ({ makeSlug, modelSlug, year }) => buildLatestVideosForGenerationPath({ make: makeSlug, model: modelSlug, year }),
    MediaModel,
    videos => !!videos?.length
  ),
});
