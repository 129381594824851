import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const SentimentSurveyMap = {
  submit_sentiment_survey: TrackingEvent.actionCompleted(TrackingConstant.ENGAGE_SURVEY, 'submit_sentiment_survey'),
  sentiment_survey_yes_comments: TrackingEvent.actionCompleted(
    TrackingConstant.ENGAGE_SURVEY,
    'sentiment_survey_yes_comments'
  ),
  sentiment_survey_no_comments: TrackingEvent.actionCompleted(
    TrackingConstant.ENGAGE_SURVEY,
    'sentiment_survey_no_comments'
  ),
};
