import { get } from 'lodash';

export function getEditorialRatingParams(editorialRating, isScorecard2025) {
  if (isScorecard2025) {
    switch (true) {
      case editorialRating < 6:
        return { text: 'poor', badgeClassName: 'poor' };
      case editorialRating >= 6 && editorialRating < 6.5:
        return { text: 'below average', badgeClassName: 'below-avg' };
      case editorialRating >= 6.5 && editorialRating < 7.6:
        return { text: 'average', badgeClassName: 'avg' };
      case editorialRating >= 7.6 && editorialRating < 8.1:
        return { text: 'good', badgeClassName: 'good' };
      case editorialRating >= 8.1 && editorialRating < 8.4:
        return { text: 'very good', badgeClassName: 'very-good' };
      default:
        return { text: 'excellent', badgeClassName: 'excellent' };
    }
  }

  switch (true) {
    case editorialRating <= 7.1:
      return { text: 'Below Average', badgeClassName: 'below-avg' };
    case editorialRating <= 7.6:
      return { text: 'Average', badgeClassName: 'avg' };
    case editorialRating <= 8:
      return { text: 'Good' };
    default:
      return { text: 'Great', badgeClassName: 'great' };
  }
}

export function getCurrentStyleId({ styles, sharedTrimName }) {
  return (styles && get(styles.find(({ trim }) => trim === sharedTrimName), 'id')) || get(styles, '[0].id');
}
