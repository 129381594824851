import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { IncentivesWizardEntities } from 'client/data/models/incentives-wizard';
import { STEP_FACTORY_COMPONENTS_MAP } from 'site-modules/shared/components/incentives/incentives-wizard/factories/step-factory-components-map';
import { useCoreIncentivesWizard, INVENTORY_TYPE } from 'site-modules/core-page/hooks/use-core-incentives-wizard';
import { getCreativeId } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-creative-id';
import { IncentivesWizardDrawer } from 'site-modules/shared/components/inventory/incentives-wizard/incentives-wizard-drawer/incentives-wizard-drawer';
import { StartScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/start-screen';
import { PersistedStateWizardContent } from 'site-modules/shared/components/inventory/incentives-wizard/wizard-content/persisted-state-wizard-content';

export function IncentivesWizardInsight({
  incentives,
  setModelValue,
  styleId,
  makeName,
  modelName,
  makeSlug,
  modelSlug,
  year,
  engineType,
  zipCode,
  isUsed,
  isMobile,
  wizardState,
  onStartOver,
  onFinish,
  isInternational,
}) {
  const [isModalOpen, toggleModalOpen] = useToggle(false);
  const creativeId = incentives && getCreativeId({ inventoryType: INVENTORY_TYPE[isUsed], incentives });
  const engineTypes = [engineType?.toLowerCase()];

  const { openWizard, wizardQuestions, wizardWidgetCommonConfig, incentivesAmount } = useCoreIncentivesWizard({
    incentives,
    wizardState,
    styleId,
    year,
    makeSlug,
    modelSlug,
    makeName,
    engineTypes,
    isUsed,
    creativeId,
    initialStepIndex: 1,
    setModelValue,
    toggleModalOpen,
    fireOpenTracking: true,
  });

  return (
    <div className="incentives-wizard-insight" data-tracking-parent={creativeId}>
      {!incentivesAmount && <div>No rebates available in {zipCode}</div>}
      {!!incentivesAmount && (
        <Fragment>
          <div className="mb-0_75">
            {isInternational
              ? 'EV Rebates vary by state. We’ll help you find all the rebates you qualify for.'
              : `Maximize savings on your ${makeName} ${modelName}.`}
          </div>
          <StartScreen
            stepConfig={{
              hideImage: true,
              className: null,
              btnClassName: 'align-self-center py-0_5 px-1',
              isInternational,
              ...wizardWidgetCommonConfig,
            }}
            context={{ make: makeName }}
            allIncentives={wizardWidgetCommonConfig.eligibleIncentives}
            onAnswer={openWizard}
            creativeId={creativeId}
          />
        </Fragment>
      )}
      <IncentivesWizardDrawer
        questions={wizardQuestions}
        stepFactoryComponentsMap={STEP_FACTORY_COMPONENTS_MAP}
        onRebatesSaveSum={onFinish}
        onToggle={toggleModalOpen}
        isOpen={isModalOpen}
        isMobile={isMobile}
        make={makeName}
        model={modelName}
        type={INVENTORY_TYPE[isUsed]}
        engineTypes={engineTypes}
        year={parseInt(year, 10)}
        creativeId={creativeId}
        component={PersistedStateWizardContent}
        initialStepIndex={wizardWidgetCommonConfig.initialStepIndex}
        initialEligibleIncentivesIds={wizardState?.eligibleIncentiveIds}
        savedAnswers={wizardState?.answers}
        onStartOver={onStartOver}
      />
    </div>
  );
}

IncentivesWizardInsight.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  styleId: PropTypes.number.isRequired,
  makeName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  makeSlug: PropTypes.string.isRequired,
  modelSlug: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  incentives: IncentivesWizardEntities.IncentivesData,
  engineType: PropTypes.string,
  zipCode: PropTypes.string,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
  onFinish: PropTypes.func,
  wizardState: IncentivesWizardEntities.IncentivesWizardUnwrappedPersistedState,
  onStartOver: PropTypes.func,
  isInternational: PropTypes.bool,
};

IncentivesWizardInsight.defaultProps = {
  incentives: null,
  engineType: null,
  zipCode: null,
  isUsed: false,
  isMobile: false,
  wizardState: undefined,
  onStartOver: noop,
  onFinish: noop,
  isInternational: false,
};
