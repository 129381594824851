import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { stripHTML } from 'site-modules/shared/utils/strip-html';
import { SCROLL_DURATION, SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { EDMUNDS_LOGO_TEXT } from 'client/images/inline-svgs/edmunds-logo-text';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { Scorecard2025RatingBar } from './scorecard-2025-rating-bar';

const COLLAPSE_THRESHOLD = { true: 145, false: 360 };

export function Scorecard2025Section({
  className,
  title,
  vehicleName,
  sectionKey,
  sectionText,
  sectionRating,
  details,
  isMobile,
}) {
  const hasTextCollapse = stripHTML(sectionText).length > COLLAPSE_THRESHOLD[isMobile];
  const id = `section-${sectionKey}`;

  const onToggleContentCollapseState = useCallback(
    isOpen => {
      if (!isOpen) {
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
          event_data: {
            action_category: TrackingConstant.USER_ACTION_CATEGORY,
            action_name: TrackingConstant.ACTION_VIEW_CONTENT,
            subaction_name: `view_scorecard_${sectionKey}`,
            creative_id: 'edm-entry-review-scorecard',
            value: title,
          },
        });
      }
    },
    [title, sectionKey]
  );

  const textFragment = (
    <ContentFragment classes="review-section-fragment text-gray-darker size-16">{sectionText}</ContentFragment>
  );

  return (
    <div
      id={id}
      className={classnames('rating-scorecard-section text-gray-darker rounded-8 bg-white px-1 py-1', className)}
    >
      <Scorecard2025RatingBar
        className="mb-0_75"
        titleClassName="heading-5"
        badgeClassName="medium"
        marginClassName="mb-0_75"
        rating={sectionRating}
        title={title}
        titleTag="h4"
        showRatingValue
      />
      {!hasTextCollapse && textFragment}
      {(hasTextCollapse || !!details?.length) && (
        <ContentCollapse
          minHeight={hasTextCollapse ? '4.125rem' : undefined} // 3 lines
          isEclipseFade={false}
          btnClasses="px-0_5 py-0 mt-0_25 size-16 text-blue-40"
          btnContainerClasses="py-0"
          justify="justify-content-start"
          showBtnBelow
          btnTextClasses="fw-normal text-underline"
          classes="size-16"
          ariaLabelHeading={`about ${title}`}
          onToggleContentCollapseState={onToggleContentCollapseState}
          trackingId={null}
          resetParams={{ to: id, offset: SCROLL_OFFSET, duration: SCROLL_DURATION }}
        >
          {hasTextCollapse && textFragment}
          {!!details?.length && (
            <div className="mt-1_5 px-md-3_5">
              {EDMUNDS_LOGO_TEXT({
                'aria-label': 'Edmunds',
                iconFillColor: '#007ee5',
                fillColor: '#4f514f',
                width: '84px',
              })}
              <h5 className="heading-6 mb-0_75">
                {vehicleName} {title} | Evaluation Criteria
              </h5>
              <ul className="list-unstyled">
                {details.map(({ label, rating }) => (
                  <li key={label} className="mb-0_75">
                    <Scorecard2025RatingBar
                      titleClassName="medium"
                      badgeClassName="small"
                      marginClassName="mb-0_25"
                      rating={rating}
                      title={label}
                      titleTag="div"
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </ContentCollapse>
      )}
    </div>
  );
}

Scorecard2025Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  vehicleName: PropTypes.string,
  sectionKey: PropTypes.string,
  sectionText: PropTypes.string,
  sectionRating: PropTypes.number,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      rating: PropTypes.number,
    })
  ),
  isMobile: PropTypes.bool,
};

Scorecard2025Section.defaultProps = {
  className: null,
  title: null,
  vehicleName: null,
  sectionKey: null,
  sectionText: null,
  sectionRating: null,
  details: null,
  isMobile: false,
};
