import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// TODO: Consider combining with getEditorialRatingParams client/site-modules/shared/utils/core-page/core-highlights.js after core-6740
function getRatingConfig(rating) {
  switch (true) {
    case rating < 6:
      return { badgeBackground: 'bg-red-100', badgeText: 'poor', barBackground: 'bg-red-80', barWidth: '16.67%' };
    case rating >= 6 && rating < 6.5:
      return {
        badgeBackground: 'bg-yellow-100',
        badgeText: 'below average',
        barBackground: 'bg-yellow-80',
        barWidth: '33.33%',
      };
    case rating >= 6.5 && rating < 7.6:
      return { badgeBackground: 'bg-orange-100', badgeText: 'average', barBackground: 'bg-orange-80', barWidth: '50%' };
    case rating >= 7.6 && rating < 8.1:
      return { badgeBackground: 'bg-purple-100', badgeText: 'good', barBackground: 'bg-purple-80', barWidth: '66.67%' };
    case rating >= 8.1 && rating < 8.4:
      return {
        badgeBackground: 'bg-turquoise-100',
        badgeText: 'very good',
        barBackground: 'bg-turquoise-80',
        barWidth: '83.33%',
      };
    default:
      return {
        badgeBackground: 'bg-green-100',
        badgeText: 'excellent',
        barBackground: 'bg-green-80',
        barWidth: '100%',
      };
  }
}

export function Scorecard2025RatingBar({
  className,
  titleClassName,
  badgeClassName,
  marginClassName,
  titleTag: TitleTag,
  title,
  rating,
  showRatingValue,
}) {
  const { badgeBackground, badgeText, barBackground, barWidth } = getRatingConfig(rating);

  return (
    <div
      className={classnames(
        'scorecard-2025-rating-bar d-flex flex-wrap justify-content-between align-items-baseline',
        className
      )}
    >
      <TitleTag className={classnames('me-1', titleClassName, marginClassName)}>{title}</TitleTag>
      <div className={classnames('px-0_5 rounded-16', badgeClassName, marginClassName, badgeBackground)}>
        {showRatingValue && (
          <Fragment>
            <strong>{parseFloat(parseFloat(rating).toFixed(1))}</strong>/10&nbsp;
          </Fragment>
        )}
        <span className="fw-medium">{badgeText}</span>
      </div>
      <div className="w-100 pos-r bg-cool-gray-80 rounded" style={{ height: '3px' }} aria-hidden>
        <div
          className={classnames('pos-a left-0 bottom-0 rounded', barBackground)}
          style={{ height: '4px', width: barWidth }}
        />
      </div>
    </div>
  );
}

Scorecard2025RatingBar.propTypes = {
  title: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  badgeClassName: PropTypes.string,
  marginClassName: PropTypes.string,
  titleTag: PropTypes.string,
  showRatingValue: PropTypes.bool,
};

Scorecard2025RatingBar.defaultProps = {
  className: null,
  titleClassName: null,
  badgeClassName: null,
  marginClassName: null,
  titleTag: 'div',
  showRatingValue: false,
};
