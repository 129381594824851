import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EDMUNDS_LOGO_NO_TEXT } from 'client/images/inline-svgs/edmunds-logo';

import './edmunds-tested-badge.scss';

export function EdmundsTestedBadge({ className }) {
  return (
    <div
      className={classnames(
        'edmunds-tested-badge ps-0_75 pe-0_5 py-0_25 fw-medium small text-primary-darker text-nowrap d-flex align-items-center',
        className
      )}
      style={{ letterSpacing: '1px' }}
    >
      {EDMUNDS_LOGO_NO_TEXT({ width: 18, fillColor: '#0069bf' })}&nbsp;edmunds&nbsp;
      <span className="fw-bold text-cool-gray-50" style={{ letterSpacing: '3px' }}>
        TESTED
      </span>
    </div>
  );
}

EdmundsTestedBadge.propTypes = {
  className: PropTypes.string,
};

EdmundsTestedBadge.defaultProps = {
  className: null,
};
