import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { EditorialReviewEntities } from 'client/data/models/editorial-review';
import { breakSectionsWithEmbeds } from 'site-modules/shared/utils/core-page/editorial-review';
import { Link } from 'site-modules/shared/components/link/link';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { EdmundsTestedBadge } from 'site-modules/core-page/components/edmunds-tested-badge/edmunds-tested-badge';
import { RatingBadge } from 'site-modules/core-page/components/rating-badge/rating-badge';
import { ArticleAuthor } from 'site-modules/shared/components/preprod-articles/article-author';
import { Scorecard2025Section } from './scorecard-2025-section';

import './scorecard-2025.scss';

const INSERT_BEFORE = [
  { key: 'mpg-or-range', embed: 'filmstripModule' },
  { key: 'in-cabin-tech', embed: 'pricingModule' },
];
const VERDICT_KEY = 'verdict';

export function Scorecard2025({ scorecard, embeddedModules, ratedBy, scale, year, makeName, modelName, isMobile }) {
  const onTestTeamTooltipOpen = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_VIEW_CONTENT,
        subaction_name: 'view_test_team_tooltip',
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: 'edm-entry-review-scorecard',
      },
    });
  }, []);

  if (!scorecard?.overallRating) {
    return null;
  }

  const { scorecardRatings, overallRating } = scorecard;

  const currentInsertBefore = INSERT_BEFORE.filter(({ embed }) => !!embeddedModules?.[embed]);
  const renderMap = breakSectionsWithEmbeds([{ key: VERDICT_KEY }, ...scorecardRatings], currentInsertBefore);

  return (
    <ScrollElement
      id="subnav-rating-scorecard"
      className="scorecard-2025 mt-2 mb-2"
      data-tracking-parent="edm-entry-review-scorecard"
    >
      {renderMap.map(({ sections, embedAfter }, index) => {
        const creativeId = `edm-entry-review-scorecard${index + 1}`;

        return (
          <Fragment key={creativeId}>
            <div
              className="bg-cool-gray-90 rounded-12 px-1 py-1 mb-1_5"
              data-tracking-parent={index ? creativeId : undefined}
            >
              {sections.map(({ key, title, sectionText, sectionRating, details }, sectionIndex) => {
                if (key === VERDICT_KEY) {
                  return (
                    <Fragment key={VERDICT_KEY}>
                      <div className="d-flex flex-wrap justify-content-between align-items-center mb-0_5">
                        <h3 className="heading-4 text-nowrap me-1 mb-0_5">Edmunds Expert Rating</h3>
                        <EdmundsTestedBadge />
                      </div>
                      <div className="px-1 py-1 bg-white rounded-8 mb-1">
                        {!!ratedBy?.authorName && (
                          <Fragment>
                            <ArticleAuthor
                              byline={
                                <Fragment>
                                  <div className="fw-bold">Driven, tested, rated by:</div>
                                  {`Edmunds ${ratedBy?.authorTitle || 'Automotive Expert'}`}
                                </Fragment>
                              }
                              tooltipProps={{
                                suffix: (
                                  <em className="d-inline-block mt-1">
                                    Rated for you by{' '}
                                    <Link
                                      to="/car-reviews/features/how-we-test-cars-and-trucks.html"
                                      className="text-underline text-primary-darker"
                                      data-tracking-id="view_content"
                                      data-tracking-parent="edm-entry-review-scorecard" // for correct tracking
                                      rel="nofollow"
                                    >
                                      America&apos;s best test team
                                    </Link>
                                    .
                                  </em>
                                ),
                                trackingParent: 'edm-entry-review-scorecard',
                                trackingSubaction: 'view_tooltip_scorecard_author',
                              }}
                              author={ratedBy}
                              className="medium"
                              lazyLoadImage
                              showBioTooltip
                            />
                            <hr className="mt-1 mb-1" />
                          </Fragment>
                        )}
                        <div className="d-flex flex-column flex-md-row-reverse align-items-center">
                          <RatingBadge
                            className="ps-md-1_25 pe-md-0_75 mb-0_75 mb-md-0"
                            badgeContainerClassName="ps-1_5 pe-1_5"
                            ratingScale={scale}
                            editorialRating={overallRating}
                            hideTestedText
                            isScorecard2025
                          />
                          <div>
                            We drove the {year} {makeName} {modelName} on our test track and on real roads. Our Edmunds
                            team conducts rigorous independent vehicle testing, driving 500,000 miles a{' '}
                            <span className="text-nowrap">
                              year.
                              <TooltipItem
                                id="rating-scorecard-tooltip"
                                className="top-0"
                                iconClassName="medium"
                                innerClassName="p-1"
                                tooltipClassName="px-10"
                                onTooltipOpen={onTestTeamTooltipOpen}
                                item={{
                                  placement: 'bottom',
                                  text:
                                    'The Edmunds Vehicle Testing Team evaluates a fresh batch of vehicles every week, pairing objective ' +
                                    'assessments at our test track with real-world driving on city streets, freeways and winding roads. The ' +
                                    'data we gather results in our Expert Ratings. They’re based on 30-plus scores that cover every aspect ' +
                                    'of the automotive experience.',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                }

                return (
                  <Scorecard2025Section
                    key={`scorecard-${key}`}
                    sectionKey={key}
                    className={classnames({ 'mt-0_5': sectionIndex })}
                    title={title}
                    vehicleName={`${makeName} ${modelName}`}
                    sectionText={sectionText}
                    sectionRating={sectionRating}
                    details={details}
                    isMobile={isMobile}
                  />
                );
              })}

              {!!embedAfter && (
                <em className="d-inline-block mt-1 medium text-cool-gray-40">
                  <i className="icon-arrow-down8 small me-0_5" aria-hidden /> Continue reading Edmunds Expert Rating
                  below
                </em>
              )}
            </div>
            {!!embedAfter && <div className="mb-1_5">{embeddedModules[embedAfter]}</div>}
          </Fragment>
        );
      })}
    </ScrollElement>
  );
}

Scorecard2025.propTypes = {
  scorecard: PropTypes.shape({
    overallRating: PropTypes.number,
    verdictText: PropTypes.string,
    scorecardRatings: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        sectionText: PropTypes.string,
        sectionRating: PropTypes.number,
        details: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            rating: PropTypes.number,
          })
        ),
      })
    ),
  }),
  embeddedModules: PropTypes.shape({
    pricingModule: PropTypes.node,
    filmstripModule: PropTypes.node,
  }),
  ratedBy: EditorialReviewEntities.AuthorData,
  scale: PropTypes.number,
  makeName: PropTypes.string,
  modelName: PropTypes.string,
  year: PropTypes.string,
  isMobile: PropTypes.bool,
};

Scorecard2025.defaultProps = {
  scorecard: null,
  embeddedModules: null,
  ratedBy: null,
  scale: 10,
  makeName: null,
  modelName: null,
  year: null,
  isMobile: false,
};
