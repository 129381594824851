import { TrackingEvent } from 'client/tracking/event';
import { CommonMap } from 'client/tracking/maps/common';
import { TrackingConstant } from 'client/tracking/constant';
import { DealerMap } from 'client/tracking/maps/common/dealer';
import { TrimPricingBarsMap } from 'client/tracking/maps/common/trim-pricing-bars';
import { SentimentSurveyMap } from 'client/tracking/maps/common/sentiment-survey/sentiment-survey';

const map = {
  core_page_website_clickout: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    'oem_site_clickout'
  ),

  filter_consumer_review_aspect: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_REVIEW,
    TrackingConstant.SEARCH_REVIEW_TOPIC,
    {
      action_cause: 'image_click',
      value: 'view_subcategory_info',
    }
  ),

  consumer_review_aspect_expand: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.VIEW_MORE
  ),

  consumer_review_aspect_collapse: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.VIEW_LESS
  ),

  core_page_go_to_review_form: TrackingEvent.actionCompleted(TrackingConstant.ACTION_WRITE_REVIEW, 'go_to_review_form'),

  core_page_go_to_price_checker: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.GO_TO_PRICE_CHECKER
  ),

  core_page_view_content_pricing_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_PRICING_DETAILS
  ),

  core_page_view_edmunds_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_REVIEW,
    TrackingConstant.VIEW_EDMUNDS_REVIEW,
    { creative_id: 'edmunds-rating-anchor' }
  ),

  core_page_view_consumer_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_REVIEW,
    TrackingConstant.VIEW_CONSUMER_REVIEW,
    { creative_id: 'consumer-rating-anchor' }
  ),

  view_ev_range: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'view_ev_range'),
  view_charging_time: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'view_charging_time'),
  view_mpg: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'view_mpg'),
  view_cost_to_drive: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'view_cost_to_drive'),
  view_reliability_ratings: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_REVIEW,
    'view_reliability_ratings'
  ),
  view_warranty: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'view_warranty'),
  view_ev_warranty: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'view_ev_warranty'),

  appraise_your_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.APPRAISE_YOUR_CAR
  ),

  core_page_view_content_specs: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_specs'),

  core_page_view_content_features: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_FEATURES
  ),

  core_page_view_content_safety_features: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'view_safety_features'
  ),

  core_page_view_content_safety_ratings: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_SAFETY_RATINGS
  ),

  core_page_change_model_dropdown: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.CHANGE_MODEL_DROPDOWN
  ),

  core_page_cbp_promotion_click: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_cbp_srp'),

  open_photoflipper: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PHOTO,
    TrackingConstant.OPEN_PHOTOFLIPPER,
    { creative_id: 'photoflipper' }
  ),

  sign_in: TrackingEvent.actionCompleted('sign_in', TrackingConstant.VIEW_PRICING_DETAILS),

  view_submodel_info: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_submodel_info'),

  view_trim_features: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_trim_features'),

  view_features_specs: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_FEATURES_SPECS
  ),

  view_consumer_review: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_consumer_review'),

  read_all_reviews: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'read_all_reviews'),

  write_review: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'write_review'),

  see_other_years: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'see_other_years'),
  see_other_years_core: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'see_other_years_core'),
  see_other_years_srp: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEE_OTHER_YEARS_SRP
  ),

  view_lease_pricing: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_lease_pricing'),

  view_incentives_deals: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_incentives_deals'),

  view_tco: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_tco'),

  view_article: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_article'),

  view_editors_choice: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_editors_choice'),

  view_more_info: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.VIEW_MORE_INFO),

  accept_push_notification_request: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_SIGN_UP,
    'accept_push_notification_request'
  ),

  decline_push_notification_request: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_SIGN_UP,
    'decline_push_notification_request'
  ),

  push_notification_navigate_close: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    'navigation_close'
  ),

  inventory_model_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_INVENTORY_SELECT,
    TrackingConstant.MODEL_SELECT
  ),

  view_vehicle_card: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_VEHICLE_CARD
  ),

  view_pros: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_pros'),

  view_cons: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_cons'),

  view_whats_new: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_whats_new'),

  year_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, 'year_select'),

  type_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, 'type_select'),

  trim_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.TRIM_SELECT),

  style_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, 'style_select'),

  mpg_model_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.MPG_MODEL_SELECT
  ),

  drivetrain_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_MMY_SELECT, 'drivetrain_select'),

  people_also_viewed_module_expand: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'expand_also_viewed_module'
  ),

  people_also_viewed_module_collapse: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'collapse_also_viewed_module'
  ),

  lease_offers_srp_link: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'lease_offers_srp_link'),

  trim_summary_srp_button: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'trim_summary_srp_button'
  ),

  pricing_cards_srp_button: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'pricing_cards_srp_button'
  ),
  floating_srp_link: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'floating_srp_link'),
  floating_used_srp_link: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'floating_used_srp_link'),
  docked_srp_button: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'docked_srp_button'),

  view_pricing_content: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_pricing_content'),
  trim_summary_srp_link: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'trim_summary_srp_link'),
  vapor_video_start: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'vapor_video_start'),
  open_photoflipper_hero: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_PHOTO, 'open_photoflipper_hero', {
    creative_id: 'photoflipper',
  }),
  open_photoflipper_thumbnail: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PHOTO,
    'open_photoflipper_thumbnail',
    { creative_id: 'photoflipper' }
  ),
  view_ev_tax_credit: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_EV_TAX_CREDIT
  ),

  short_video_start: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'short_video_start'),
  video_close: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'video_close'),

  view_content_docked: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_content_docked'),
  view_content_non_docked: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'view_content_non_docked'
  ),

  compare_trims: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'compare_trims'),
  compare_core_link: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'compare_core_link'),
  compare_checkbox: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'compare_checkbox'),
  compare_cars: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'compare_cars'),
  compare_cars_custom: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'compare_cars_custom'),
  compare_cars_any: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'compare_cars_any'),
  compare_vehicle_ranking: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'compare_vehicle_ranking'
  ),

  vin_photo_click: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'vin_photo_click'),
  visit_buy_online_channel: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'visit_buy_online_channel'
  ),
  for_sale_button_click: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'for_sale_button_click'),
  ev_charging_article: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'ev_charging_article'),
  honda_hybrid_link: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'honda_hybrid_link'),

  expand_verdict: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'expand_verdict'),

  view_share_options: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_share_options'),

  click_trim_label: TrackingEvent.actionCompleted(TrackingConstant.VIEW_PRICING_DETAILS, 'click_trim_label'),

  view_shop_module: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'view_shop_module'),

  // TODO: Review after core-6292
  see_vin_listings: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'see_vin_listings'),

  // TODO: Review after core-6393
  view_EV_insights: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_EV_insights'),

  // TODO: Review after core-6457
  view_recommended_trim: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_REVIEW, 'view_recommended_trim'),
  recommended_trim_srp_link: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'recommended_trim_srp_link'
  ),

  // TODO: Review after core-6558
  view_ev_eligibility_wizard: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'view_ev_eligibility_wizard'
  ),

  // TODO: Review after core-6718
  view_review_content: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_review_content'),
};

export const TrackingMap = {
  ...CommonMap,
  ...DealerMap,
  ...TrimPricingBarsMap,
  ...SentimentSurveyMap,
  ...map,
};
